














































import { computed, defineComponent } from '@nuxtjs/composition-api'
import { chunk } from 'lodash'
import { sectionCoreProps } from '@/mixins/sectionCore'
import SectionBasic from '@/components/SectionBasic.vue'
import BlockGridAdvantagesItem from '@/components/BlockGridAdvantagesItem.vue'
import TableWithTwoCols from '@/components/TableWithTwoCols.vue'
export default defineComponent({
  name: 'SectionGridAdvantages',
  components: { TableWithTwoCols, BlockGridAdvantagesItem, SectionBasic },
  mixins: [sectionCoreProps],
  props: {
    details: {
      type: String,
      default: '',
    },
    cardsList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const chunkedCards = computed(() => chunk(props.cardsList, 2))
    const agileOptions = computed(() => ({
      navButtons: false,
      infinite: false,
      dots: true,
      slidesToShow: 1,
      swipeDistance: 50,
    }))

    return {
      chunkedCards,
      agileOptions,
    }
  },
})






import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'TableWithTwoCols',
  props: {
    inner: {
      type: String,
      default: '',
    },
  },
})

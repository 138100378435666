















import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'BlockGridAdvantagesItem',
  props: {
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
})
